.pop-enter { 
  opacity: 0;
  transform: scale(0.99) translateY(-3px);
  transform-origin: top right;
}

.pop-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 100ms linear 100ms, transform 100ms ease-in-out 100ms;
}

.pop-enter-done{
  opacity: 1;
}

.pop-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
  transform-origin: top right;
}

.pop-exit-active {
  opacity: 0;
  transform: scale(0.99) translateY(-3px);
  transition: opacity 100ms linear 100ms, transform 100ms ease-in-out 100ms;
}

.pop-exit-done{
  opacity: 0;
}